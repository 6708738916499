<template>
  <nut-popup v-model="show" round position="bottom" @close="onClose">
    <div class="useRSQV1">
      <img
        :src="require('@STATIC/image/icons/closeFill-gray.png')"
        @click.stop="onClose"
      />
      <div class="useRSQV1_title">购买人生券</div>
      <div class="useRSQV1_tip">购买人生券抵扣现金优惠</div>
      <div class="useRSQV1_body" @click.stop="active = !active">
        <div>
          <span>{{ renshengquan_info.title }}</span>
          <span>{{ renshengquan_info.buy_number }}</span>
        </div>
        <div>
          <span>￥{{ renshengquan_info.need_money }}</span>
          <img
            :src="
              active
                ? require('@STATIC/image/icons/checked.png')
                : require('@STATIC/image/icons/checked-gray.png')
            "
          />
        </div>
      </div>
      <p>提示:</p>
      <p>1.本单使用{{ renshengquan_info.will_use }}人生券，剩余存放钱包中</p>
      <p>2.发生退款时，所购买的人生券将返回到钱包中</p>
      <div class="useRSQV1_foot">
        <div>
          <div>
            <span>人生券抵扣</span>
            <span>¥{{ renshengquan_info.will_use }}</span>
          </div>
          <span>人生券费用¥{{ renshengquan_info.need_money }}同订单支付</span>
        </div>
        <!-- @click.stop="confirm" -->
        <button v-debounce="['click', confirm, 500]">确定</button>
      </div>
    </div>
  </nut-popup>
</template>

<script>
export default {
  name: "useRSQV1",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    renshengquan_info: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      show: false,
      active: false,
    };
  },
  watch: {
    isShow() {
      this.show = this.$props.isShow;
      this.active = this.$props.isActive;
    },
    isActive() {
      this.active = this.$props.isActive;
    },
  },
  mounted() {
    this.show = this.$props.isShow;
    this.active = this.$props.isActive;
  },

  methods: {
    onClose() {
      this.show = false;
      this.$emit("onClose");
    },
    onChange() {},
    confirm() {
      this.onClose();
      this.$emit("confirm", this.active);
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(useRSQV1) {
  position: relative;
  width: 375px;
  min-height: 300px;
  background: #ffffff;
  border-radius: 34px 34px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > img {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 22px;
    right: 22px;
  }
  @include e(title) {
    margin-top: 23px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  @include e(tip) {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 30px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  @include e(body) {
    margin-top: 16px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    & > div:first-of-type {
      background: no-repeat 100%/100%
        url("../../../static/image/icons/coupon.png");
      box-sizing: border-box;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 189px;
      height: 58px;
      color: #fff;
      font-size: 20px;
      & > span:last-of-type::after {
        content: "张";
        font-size: 12px;
      }
    }
    & > div:last-of-type {
      display: flex;
      align-items: center;
      & > img {
        width: 16px;
        height: 16px;
        margin-left: 9px;
      }
    }
  }
  @include e(foot) {
    margin-top: 17px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 375px;
    height: 56px;
    background: #ffffff;
    box-shadow: 0px 0px 0px 0px rgba(209, 209, 209, 1);
    padding: 0 15px 0 20px;
    border-top: 1px solid #ebebeb;
    & > div {
      & > div {
        margin-bottom: 7px;
        & > span:first-of-type {
          margin-right: 5px;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #f46a17;
      }
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    & > button {
      width: 115px;
      height: 36px;
      background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
      border-radius: 18px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      &:active {
        opacity: 0.5;
      }
    }
  }
  & > p {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0;
    margin-top: 10px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #939393;
  }
}
</style>
