<template>
    <div class="input-container">
        <div class="title">{{title}}</div>
        <div class="input-render-container">
            <!--<div class="input-show" :class="[active ? 'active' : '']" v-show="showInput == false"-->
            <!--@click="showRealInput">-->
            <!--{{showText}}-->
            <!--</div>-->
            <div class="input-real">
                <input :placeholder="placeholder" ref="inputs"  v-model="value" type="text" @blur="onInputBlur"
                       :style="styleObj" style="text-align: right;"/>
            </div>
            <div class="unit" v-if="unit.length > 0">{{unit}}</div>
        </div>
        <div class="disable-div" v-if="disabled"></div>
    </div>
</template>

<script>
  export default {
    name: 'services-input',
    props: {
      title: {
        type: String,
        default: ''
      },
      placeholder: {
        type: [String, Number],
        default: ''
      },
      unit: {
        type: [String, Number],
        default: ''
      },
      defaultValue: {
        type: [String, Number],
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: ''
      }
    },
    mounted () {
      this.showText = this.$props.placeholder
      let defaultValue = this.$props.defaultValue
      if (defaultValue.length > 0) {
        this.value = defaultValue
        this.showText = defaultValue
        this.active = true
      }
    },
    data () {
      return {
        value: '',
        showInput: true,
        showText: '',
        active: false
      }
    },
    computed: {
      styleObj () {
        let object = {
          textALign: 'right',
          marginRight: 0
        }
        if (this.$props.unit) {
          object.marginRight = '3px'
        } else {
          object.marginRight = '15px'
        }

        return object
      }
    },
    methods: {
      showRealInput () {
        if (this.$props.disabled) {
          return
        }
        this.showInput = true
        this.$refs.inputs.focus()
      },
      onInputBlur () {
        if (this.value.length > 0) {
          this.showText = JSON.parse(JSON.stringify(this.value))
          this.active = true
        } else {
          this.showText = this.$props.placeholder
          this.active = false
        }
        this.showInput = false
        this.$emit('onInputBlur', {name: this.$props.name, value: this.value})
        this.$forceUpdate()
      },
      getValue () {
        return this.value
      },
      setValue (value) {
        this.value = value
        this.showText = value
        this.active = true
        this.$forceUpdate()
      }
    }
  }
</script>

<style scoped lang="scss">
    .input-container {
        display: flex;
        align-items: baseline;
        position: relative;

        .title {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
            font-family: PingFangSC-Semibold, PingFang SC;
        }

        .disable-div {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        .input-render-container {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .input-show {
                flex: 1;
                color: #939393;
                font-size: 14px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                min-height: 20px;
                margin-left: 30px;
                text-align: right;
                word-break: break-all;
            }

            .active {
                color: #333333;
            }

            .input-real {
                flex: 1;
                display: flex;

                input {
                    flex: 1;
                    border: none;
                    outline: none;
                    min-height: 20px;
                    font-size: 14px;
                    margin-left: 10px;
                    display: flex;
                    justify-content: flex-end;
                    color: #333333;
                }
            }

            .unit {
                color: #333333;
                font-size: 14px;
                margin-left: 8px;
            }
        }
    }
</style>