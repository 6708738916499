<template>
  <!--订单确认页面-->
  <rsck-page
    :showPageBottomButton="true"
    bottomHeight="56"
    background="#f6f6f6"
    v-if="showResult"
  >
    <rsck-refresh
      ref="refresh"
      :lockPullUp="true"
      padding="0"
      @onPullDown="onPullDown"
      :height="scrollHeight"
    >
      <div class="top-card">
        <div class="shop-name">
          <div class="shop-logo">
            <img
              src="https://api.mengjingloulan.com/storage/wechat/services/store.png"
            />
          </div>
          <div class="shop-name-text">梦回楼兰</div>
        </div>
        <div class="goods-info">
          <div class="images">
            <img :src="info.cover_path" />
          </div>
          <div class="goods-info-right">
            <div class="goods-title">
              {{ info.title }}
            </div>
            <div class="deduct">
              <template v-if="info.deduct_renshengquan">
                可抵扣{{ info.deduct_renshengquan }}元人生券
              </template>
              <template v-if="!info.deduct_renshengquan"></template>
            </div>
            <div class="need-pay">
              需付款 ¥{{ saveInfo.total_market_price }}
            </div>
          </div>
        </div>
        <div class="warning-text" v-if="listTypeId == 1">
          该商品为二手物品，取货时请核对，领取后不可退换
        </div>
      </div>

      <div class="middle-card" v-if="listTypeId == 2">
        <cell-v3 :hide-icon="true" class="cell-v3">
          <div slot="title" class="title">联系人</div>
          <div class="">
            <services-input
              name="name"
              :default-value="name"
              placeholder="请输入联系人姓名"
              @onInputBlur="onInputBlur"
            />
          </div>
        </cell-v3>

        <cell-v3 :hide-icon="true" class="cell-v3">
          <div slot="title" class="title">联系电话</div>
          <div class="">
            <services-input
              name="mobile"
              :default-value="mobile"
              placeholder="请输入联系人电话"
              @onInputBlur="onInputBlur"
            />
          </div>
        </cell-v3>

        <cell-v3 :hide-icon="true" class="cell-v3 no-border">
          <div slot="title" class="title">服务地址</div>
          <div class="">
            <services-input
              name="address"
              :default-value="address"
              placeholder="请输入服务地址"
              @onInputBlur="onInputBlur"
            />
          </div>
        </cell-v3>
      </div>

      <div class="middle-card" v-if="listTypeId == 2">
        <cell-v3 :hide-icon="true" class="cell-v3">
          <div slot="title" class="title">服务方式</div>
          <div class="action">同城服务</div>
        </cell-v3>

        <cell-v3 :hide-icon="true" class="cell-v3">
          <div slot="title" class="title">服务费用</div>
          <div class="action">¥{{ info.price }}</div>
        </cell-v3>

        <cell-v3
          :hide-icon="true"
          class="cell-v3"
          v-if="info.deduct_renshengquan"
        >
          <div slot="title" class="title">人生券抵扣</div>
          <div class="action color-special" @click="showUseRenshengquan">
            - ¥{{ canUseRenshengquan }}
          </div>
        </cell-v3>

        <cell-v3 :hide-icon="true" class="cell-v3">
          <div slot="title" class="title" @click="timeSelect">服务时间</div>
          <div class="action" @click="timeSelect">
            <template v-if="services_time">
              {{ services_time }}
            </template>
            <template v-if="!services_time">
              <img
                class="down-img"
                src="https://api.mengjingloulan.com/storage/wechat/services/down-light.png"
              />
            </template>
          </div>
        </cell-v3>

        <cell-v3 :hide-icon="true" class="cell-v3 no-border">
          <div slot="title" class="title">留言</div>
          <div class="">
            <services-input
              name="remark"
              placeholder="请输入您的备注"
              :default-value="remark"
              @onInputBlur="onInputBlur"
            />
          </div>
        </cell-v3>
      </div>

      <div class="middle-card" v-if="listTypeId == 1">
        <cell-v3 :hide-icon="true" class="cell-v3">
          <div slot="title" class="title">配送方式</div>
          <div class="action">上门取货</div>
        </cell-v3>

        <cell-v3 :hide-icon="true" class="cell-v3">
          <div slot="title" class="title">商品金额</div>
          <div class="action">¥{{ info.price }}</div>
        </cell-v3>

        <cell-v3
          :hide-icon="true"
          class="cell-v3"
          v-if="info.deduct_renshengquan"
        >
          <div slot="title" class="title">人生券抵扣</div>
          <div class="action color-special" @click="showUseRenshengquan">
            - ¥{{ canUseRenshengquan }}
          </div>
        </cell-v3>

        <cell-v3 :hide-icon="true" class="cell-v3 no-border">
          <div slot="title" class="title">留言</div>
          <div class="">
            <services-input
              name="remark"
              :default-value="remark"
              placeholder="请输入您的备注"
              @onInputBlur="onInputBlur"
            />
          </div>
        </cell-v3>
      </div>

      <discount-v1
        :show="showDiscountDetail"
        @onClose="showDiscountDetail = false"
        :info="discountInfo"
        :hideLijian="true"
      />
    </rsck-refresh>
    <div slot="pageBottomButtons">
      <footbar-v2
        @showDetail="showDiscountDetail = true"
        :info="saveInfo"
        :order_info="orderInfo"
        :buyRSQ="isBuyRSQ"
        @goBuy="goBuy"
      />
    </div>
    <use-r-s-q-v1
      :isShow="useRSQ"
      :isActive="isBuyRSQ"
      :renshengquan_info="renshengquanInfo"
      @onClose="useRSQ = false"
      @confirm="onUseRSQ"
    />

    <nut-tabselect
      :mainTitle="mainTitle"
      :subTitle="subTitle"
      :defaultContent="defaultContent"
      :tabList="tabList"
      :show="showTimeSelect"
      @close="showTimeSelect = false"
      @choose="chooseTime"
      :multiple="false"
      :isDefaultSelected="true"
    ></nut-tabselect>
  </rsck-page>
</template>

<script>
import RsckPage from "@/components/common/rsck-page";
import RsckRefresh from "@/components/common/rsck-refresh";
import FootbarV2 from "@/template/community/footbar/footbarV2";
import CellV3 from "@/template/community/cell/cellV3";
import ServicesInput from "@/pages/services/components/services-input";
import DiscountV1 from "@/template/community/popup/discountV1";
import UseRSQV1 from "@/template/community/popup/useRSQV1";

export default {
  name: "order-confirm",
  components: {
    UseRSQV1,
    DiscountV1,
    ServicesInput,
    CellV3,
    FootbarV2,
    RsckRefresh,
    RsckPage,
  },
  mounted() {
    document.title = "确认订单";
    let query = this.$route.query;
    this.listTypeId = query?.listTypeId || 1;
    this.id = query?.id || 0;
    this.getCalendar();
    this.init();
    this.scrollHeight = window.innerHeight - 56;
  },
  data() {
    return {
      showResult: false,
      listTypeId: 1,
      id: 0,
      showDiscountDetail: false,
      scrollHeight: 0,
      info: {},
      saveInfo: {},
      renshengquanInfo: {},
      useRSQ: false,
      remark: "",
      isBuyRSQ: false, //是否购买人生券
      name: "",
      address: "",
      mobile: "",
      services_time: "", //服务时间，
      showTimeSelect: false, //是否显示选择时间,
      mainTitle: "",
      subTitle: "",
      defaultContent: [
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
      ],
      tabList: [
        {
          tabTitle: "服务时间", // 一级tab标题
          children: [],
        },
      ],
      canContinue: true,
    };
  },
  computed: {
    canUseRenshengquan() {
      if (this.isBuyRSQ) {
        //如果是购买人生券，可以抵扣全部的人生券
        return this.saveInfo.can_use_renshengquan;
      } else {
        return this.saveInfo.real_use_renshengquan;
      }
    },
    orderInfo() {
      return {
        goods_amount: this.info.price, //订单价格
        can_use_renshengquan: this.saveInfo.can_use_renshengquan, //可以使用的人生券数量
        real_can_use_renshengquan: this.saveInfo.real_use_renshengquan, //未勾选购买人生券 可以使用的人生券数量
        buy_renshengquan_pay_money: this.renshengquanInfo.need_money, //购买人生券需要支付的金额
        shipping_fee: 0, //运费
        pay_amount: this.$utils.math.sub(
          this.info.price,
          this.saveInfo.can_use_renshengquan
        ), //会员需要支付的金额
        real_pay_amount: this.saveInfo.total_market_price, //未勾选购买人生券 会员需要支付的金额
      };
    },
    discountInfo() {
      return {
        total_product_price: this.info.price,
        save_money: this.saveInfo.total_save_money,
        can_use_renshengquan: this.canUseRenshengquan,
        total_save_money: this.saveInfo.total_save_money,
      };
    },
  },
  methods: {
    init() {
      this.getConfirmOrderInfo().then(() => {
        this.$refs.refresh.endPullDown();
      });
    },
    getConfirmOrderInfo() {
      return new Promise((resolve, reject) => {
        this.$api.services
          .getConfirmOrderInfo({ list_type_id: this.listTypeId, id: this.id })
          .then((response) => {
            let data = response.data;

            //详情
            this.info = data.info;
            //节省的金额
            this.saveInfo = data.save_info;
            //人生券信息
            this.renshengquanInfo = {
              ...data.needBuyInfo?.renshengquan,
              will_use: this.saveInfo.can_use_renshengquan,
              need_money: data.needBuyInfo.need_money,
            };

            let needBuyNumber = data.needBuyInfo?.buy_number || 0;

            this.showResult = true;
            resolve();
          })
          .catch((error) => {
            this.$toast.fail(error.message);
            reject();
          });
      });
    },
    onPullDown() {
      this.init();
    },
    goBuy() {
      //调用创建订单接口
      if (this.canContinue) {
        this.canContinue = false;

        let params = {
          list_type_id: this.listTypeId,
          id: this.id,
          buy: this.isBuyRSQ == true ? 1 : 0,
          remark: this.remark,
        };

        if (this.listTypeId == 2) {
          params.user_name = this.name;
          params.user_address = this.address;
          params.user_mobile = this.mobile;
          params.service_time = this.services_time;
        }

        if (this.listTypeId == 2) {
          if (!params.user_name) {
            this.$toast.fail("请输入联系人姓名");
            return;
          }

          if (!params.user_address) {
            this.$toast.fail("请输入服务地址");
            return;
          }

          if (!params.user_mobile) {
            this.$toast.fail("请输入联系人手机号码");
            return;
          }

          if (!params.service_time) {
            this.$toast.fail("请选择服务时间");
            return;
          }
        }

        this.$api.services
          .createOrder(params)
          .then((response) => {
            //跳转到支付页面
            this.payInfo = {
              ...response.data,
              listTypeId: this.listTypeId,
            };
            let options = encodeURIComponent(JSON.stringify(this.payInfo));
            this.$platform.wxsdk.wxRoute({
              type: "redirectTo",
              url: "/web/money/service-pay?query=" + options,
            });
          })
          .catch((error) => {
            this.$toast.fail(error.message);
          });

        setTimeout(() => {
          this.canContinue = true;
        }, 500);
      }
    },
    showUseRenshengquan() {
      if (this.renshengquanInfo.hasOwnProperty("id")) {
        this.useRSQ = true;
      } else {
        this.useRSQ = false;
      }
    },
    onUseRSQ(data) {
      this.isBuyRSQ = data;
    },
    onInputBlur(data) {
      let name = data.name;
      let value = data.value;
      if (name == "name") {
        this.name = value;
      } else if (name == "address") {
        this.address = value;
      } else if (name == "mobile") {
        this.mobile = value;
      } else if (name == "remark") {
        this.remark = value;
      }
    },
    getCalendar() {
      this.$api.services.getCalendar().then((response) => {
        this.tabList[0].children = [];
        response.data.forEach((data) => {
          this.tabList[0].children.push({
            tabTitle: data,
          });
        });
      });
    },
    chooseTime(title, item) {
      this.services_time = item[0].subTit + " " + item[0].content;
    },
    timeSelect() {
      this.showTimeSelect = true;
    },
  },
};
</script>

<style scoped lang="scss">
.top-card {
  background: #fff;
  padding: 18px 15px;
  display: flex;
  flex-wrap: wrap;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 10px;

  .shop-name {
    width: 100%;
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    height: 19px;
    margin-bottom: 12px;

    .shop-logo {
      width: 19px;
      height: 19px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .shop-name-text {
      flex: 1;
      display: flex;
      align-items: center;
      line-height: 19px;
      height: 19px;
    }
  }

  .goods-info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .images {
      width: 109px;
      height: 109px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      border-radius: 8px;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .goods-info-right {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      .goods-title {
        width: 100%;
        color: #333333;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
        height: 14px;
      }

      .deduct {
        width: 100%;
        color: #f46a17;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
      }

      .need-pay {
        width: 100%;
        display: flex;
        color: #f46a17;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }

  .warning-text {
    width: 100%;
    color: #df8621;
    font-size: 12px;
    background-color: #fff9ec;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 8px 10px;
    margin-top: 14px;
  }
}

.middle-card {
  padding: 18px 20px 0 20px;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  .cell-v3 {
    width: 100%;
    margin-bottom: 18px;
    padding-bottom: 12px;
    border-bottom: #ebebeb solid 1px;

    .title {
      color: #333333;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
    }

    .action {
      color: #333333;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: bold;

      .down-img {
        width: 20px;
        height: 20px;
      }
    }

    .color-special {
      color: #eb6100;
      font-size: 14px;
    }
  }

  .no-border {
    border: none;
    margin-bottom: 0;
  }
}

/deep/ .nut-tabselect .nut-tabselect-btn a {
  font-size: 14px;
}

/deep/.cell-v3 .title {
  align-items: baseline;
}
</style>