<template>
  <div class="footbarV2">
    <div>
      <div>总计: ¥{{ payPrice }}</div>
      <div>
        <span>优惠减{{ info.total_save_money }}元</span>
        <span @click="showDetail">优惠明细</span>
      </div>
    </div>
    <button @click="goBuy">去结算</button>
  </div>
</template>

<script>
/**
 * 订单提交底部模块
 */
export default {
  name: "footbarV2",
  props: {
    //是否购买人生券
    buyRSQ: {
      type: Boolean,
      default: false,
    },
    //订单信息
    order_info: {
      type: Object,
      default() {
        return {
          express_type: 0, //配送类型 1:自提点配送 2:快递配送
          allow_express_type: [1], //是个是否可以切换配送类型的数组，如果只包含一个值，则不能切换配送类型，如果包含2个值，则允许切换配送类型，其中0代表快递配送 1代表自提点配送
          goods_amount: 0, //订单价格
          can_use_renshengquan: 0, //可以使用的人生券数量
          real_can_use_renshengquan: 0, //未勾选购买人生券 可以使用的人生券数量
          buy_renshengquan_pay_money: 0, //购买人生券需要支付的金额
          shipping_fee: 0, //运费
          pay_amount: 0, //会员需要支付的金额
          real_pay_amount: 0, //未勾选购买人生券 会员需要支付的金额
        };
      },
    },
    //优惠信息
    info: {
      type: Object,
      default() {
        return {
          save_money: 0,
          total_save_money: 0,
        };
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    payPrice() {
      if (
        this.$props.order_info.real_can_use_renshengquan <
        this.$props.order_info.can_use_renshengquan
      ) {
        //需要购买人生券
        if (this.$props.buyRSQ) {
          //选择了购买人生券
          return this.$utils.math.add(
            this.$props.order_info.pay_amount,
            this.$props.order_info.buy_renshengquan_pay_money
          );
        } else {
          return this.$props.order_info.real_pay_amount;
        }
      } else {
        return this.$props.order_info.pay_amount;
      }
    },
  },
  methods: {
    showDetail() {
      this.$emit("showDetail");
    },
    goBuy() {
      this.$emit("goBuy");
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(footbarV2) {
  width: 375px;
  height: 56px;
  background: #ffffff;
  box-shadow: 0px 0px 0px 0px rgba(209, 209, 209, 1);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    box-sizing: border-box;
    padding: 11px 0 11px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > div {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      &:last-of-type {
        display: flex;
        align-items: center;
        & > span {
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #eb6100;
          &:last-of-type {
            margin-left: 9px;
            background: #ebebeb;
            border-radius: 8px;
            box-sizing: border-box;
            padding: 3px 6px;
            color: #939393;
            &:active {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
  & > button {
    margin-right: 14px;
    width: 115px;
    height: 36px;
    background: linear-gradient(139deg, #fac484 0%, #f46a17 100%);
    border-radius: 18px;
    border: none;
    outline: none;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      opacity: 0.5;
    }
  }
}
</style>
